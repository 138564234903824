<template>
  <div class="contactUs">
    <section>
      <v-layout justify-center class="my-5">
        <v-row>
          <h1 class="display-1 iceBlue mx-12">{{content.contactUs}}</h1>
        </v-row>
      </v-layout>
    </section>
    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-12">
          <v-flex xs12>
            <center>
              <v-card flat class="transparent">
                <!-- <v-card-title primary-title class="layout">
                  <h1 class="text-left">{{content.contactUs}}</h1>
                </v-card-title>-->
                <v-row>
                  <v-carousel
                    hide-delimiter-background
                    show-arrows-on-hover
                    height="inherit"
                    class="max500Height"
                  >
                    <v-carousel-item align="center" v-for="(item,i) in shopImages" :key="i">
                      <v-img :src="item.src" contain :alt="item.src" class="max500Height" />
                    </v-carousel-item>
                  </v-carousel>
                </v-row>
                <center>
                  <v-card flat class="transparent" width="600">
                    <v-card-text
                      v-scrollanimationx
                      class="headline iceBlue"
                    >{{content.questionContact}}</v-card-text>
                    <!-- <div>
                        {{item.icon}}
                    </div>-->
                    <v-list class="transparent">
                      <v-row v-for="(item,i) in shopDetails" :key="i" align="center">
                        <!-- <v-list-item v-for="(item,i) in shopDetails" :key="i"> -->

                        <!-- <v-list-item-content class="py-0" > -->
                        <!-- <v-list-item-title class="my-0"> -->
                        <!-- <v-list-item-action style="float:left" class="my-1"> -->
                        <v-col cols="2" class="py-0">
                          <v-icon class="blue--text text--lighten-2 my-0">{{item.icon}}</v-icon>
                        </v-col>
                        <v-col cols="10" class="py-0">
                          <!-- </v-list-item-action> -->
                          <div style="text-align:center;" class="title" v-html="item.content"></div>
                        </v-col>
                        <!-- </v-list-item-title> -->
                        <!-- </v-list-item-content> -->
                        <!-- </v-list-item> -->
                      </v-row>

                      <v-row align="center">
                        <v-col cols="2" class="py-0">
                          <v-icon class="blue--text text--lighten-2">mdi-text-to-speech</v-icon>
                        </v-col>
                        <v-col cols="10" class="py-0">
                          <div style="text-align:center;" class="title">
                            <v-btn
                              large
                              v-for="(social, i) in socials"
                              :key="i"
                              :color="social.color"
                              class="pl2 pr2 red--text"
                              :href="social.link"
                              fab
                              icon
                              small
                            >
                              <v-img
                                v-if="social.extraImage"
                                aspect-ratio="1"
                                max-width="50"
                                max-height="50"
                                :src="social.extraImage"
                              ></v-img>
                              <v-icon v-else>{{ social.icon }}</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <!-- <v-list-item>
                        <v-list-item-content class="py-1">
                          <v-list-item-title>
                            <v-list-item-action style="float:left">
                              <v-icon class="blue--text text--lighten-2">mdi-text-to-speech</v-icon>
                            </v-list-item-action>
                            <div style="text-align:center;" class="title">
                              <v-btn
                                large
                                v-for="(social, i) in socials"
                                :key="i"
                                :color="social.color"
                                class="pl2 pr2 red--text"
                                :href="social.link"
                                fab
                                icon
                                small
                              >
                                <v-icon>{{ social.icon }}</v-icon>
                              </v-btn>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>-->
                    </v-list>
                  </v-card>
                </center>

                <!-- <a
                  :href="'https://www.google.com/maps/search/?api=1&query='+encodeURI('WeWatch, Shop 8, G/F, Yan On Building, 1st Kwong Wa Street, Mong Kok, Hong Kong')"
                >
                  <v-img :aspect-ratio="1/1" src="@/assets/map-zh.png" />
                </a>
                -->
              </v-card>
            </center>
            <!-- <v-img :aspect-ratio="16/9" src="@/assets/shop-1.jpg" />
            <span></span>
            <v-img :aspect-ratio="16/9" src="@/assets/shop-2.jpg" />
            <span></span>
            <v-img :aspect-ratio="16/9" src="@/assets/shop-3.jpg" />-->
          </v-flex>
        </v-layout>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-layout>
          <v-card class="fullwidth">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.9946527851016!2d114.1704900508834!3d22.316041985243228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040120be48b881%3A0xfae1590687de50b8!2sWeWatch!5e0!3m2!1sen!2shk!4v1578191452443!5m2!1sen!2shk"
              width="100%"
              height="600"
              frameborder="0"
              style="border:0;"
              allowfullscreen
            ></iframe>
          </v-card>
        </v-layout>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row>
          <h1>{{content.termsAndConditions}}</h1>
        </v-row>
        <v-row>
          <div align="left">
            <p>
              In trading with WeWatch Limited, purchaser is automatically bound by WeWatch’s Terms and
              Conditions, which may be updated from time to time without notification to the purchaser. All updates will
              be uploaded to WeWatch’s official website (www.wewatch.com.hk) and shall only bind any transactions
              succeeding the time and date that the updates take effect.
            </p>
            <p>
              Purchaser should expressly agree that he/she accept the purchased item on an “as-is” basis pursuant to
              his/her personal inspection of the item free from representation as to its conditions. Subject to
              Paragraph 1 above, the purchased item shall not be refunded.
            </p>
            <p>
              Due to the nature of pre-owned timepieces, all watches are sold “as is”. WeWatch Limited makes no
              mechanical guarantee in terms of movement, accuracy or waterproofing.
            </p>
            <p>
              For International buyers, WeWatch Limited guarantee all watches are in our possession and will be
              express shipped immediately upon your funds clearing our Bank Account. All watches are shipped fully
              insured. If a package is lost, a refund will be issued once the insurance claim is approved and paid.
              Buyers are responsible for any taxes, tariffs, customs or duties due in the country of destination.
              International buyers assume all responsibility for any and all duties, taxes, tariffs or customs due. The
              buyer takes all responsibility for shipping in the event of a customs issue or return.
            </p>
          </div>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
v-img {
  width: 100%;
  height: 100%;
}

span {
  margin: 5px;
}
</style>
<script>
export default {
  data () {
    return {
      content: {},
      shopDetails: [
        { icon: 'mdi-phone', content: '(+852) 2522 0123' },
        { icon: ' mdi-fax', content: '(+852) 2522 0124' },
        { icon: 'mdi-whatsapp', content: '(+852) 6881 9431' },
        {
          icon: 'mdi-wechat',
          content: 'WeWatchHK'
        },
        {
          icon: 'mdi-email',
          content: 'info@wewatch.com.hk'
        },
        {
          icon: 'mdi-map-marker',
          content:
            '<p>Shop 8A, G/F, Yan On Building,<br>Kwong Wa Street, Mong Kok, Hong Kong</p>'
        },
        { icon: 'mdi-clock-check-outline', content: '12:00 - 20:30' }
      ],
      shopImages: [
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fshop-4-2.png?alt=media&token=aaa5b1f2-9831-41c8-ba9c-37fa49d6b115'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fshop-3.jpg?alt=media&token=133282ec-c768-4f32-8d1a-dd38f5524b7d'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fshop-2.jpg?alt=media&token=1b57f2ec-100e-4c82-b40a-9b5f94658bb0'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2Fshop-1.2.jpg?alt=media&token=6319f1ab-ab9d-4de3-90b1-e6b468acf612'
        }
      ],
      // whatsapp, Carousell, IG, FB,  WeChat
      socials: [
        {
          icon: 'fab fa-whatsapp',
          color: 'green lighten-3',
          link:
            'https://wa.me/85268819431/?text=' +
            encodeURI('我想揾一隻適合我嘅手錶!')
        },
        {
          icon: 'fas fa-camera-retro',
          extraImage:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon2.png?alt=media&token=26e50ca6-557b-4360-8afd-7c3b081347a1',
          color: 'orange lighten-3',
          link: 'https://hk.carousell.com/wewatch'
        },
        {
          icon: 'fab fa-instagram',
          color: 'red lighten-3',
          link: 'https://www.instagram.com/wewatchhk/'
        },
        {
          icon: 'fab fa-facebook',
          color: 'blue',
          link: 'https://www.facebook.com/wewatchhk/'
        },
        {
          icon: 'fab fa-weixin',
          color: 'green',
          link: 'weixin://' // https://wx.qq.com/
        }
      ]
    }
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        contactUs: '聯絡我們',
        questionContact: '如果對買錶有任何問題,歡迎隨時WhatsApp我們',
        termsAndConditions: '條款及細則'
      }
    } else {
      this.content = {
        contactUs: 'Contact Us',
        questionContact:
          'If you have any questions, please feel free to contact us',
        termsAndConditions: 'Terms and conditions'
      }
    }
  }
}
</script>
<style scoped>
/*
.heightDouble{
  height: 2rem;
} */
.inheritHeight {
  height: inherit;
}
.max500Height {
  max-height: 500px;
}
</style>
